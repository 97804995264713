import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Circle from "../assets/image/about/faded-circle.svg"
import teacherStudent from "../assets/image/teacherStudent.svg"
import pcclock from "../assets/image/pcclock.svg"
import money from "../assets/image/money.svg"
import one from "../assets/image/1blue.svg"
import two from "../assets/image/2blue.svg"
import Offer1 from "../assets/image/offer1.png"
import partner13 from "../assets/image/partner13.png"
import partner11 from "../assets/image/partner11.png"
import partner5 from "../assets/image/partner5.png"
import partner9 from "../assets/image/partner9.png"
import partner12 from "../assets/image/partner12.png"
import story1 from "../assets/image/story1.png"
import pageTopBg from "../assets/image/page-top-bg.png"
import { Link } from "gatsby"
import newlogo from "../assets/image/newLogo.svg"
import trainer2 from "../assets/image/trainer2.png"
import _ from "lodash"
import { backendHost, webappHost } from "../../config"
import { wireEventValue } from "../utils/form"
import { makePostRequest } from "../utils/request"
import CourseCard from "../components/CourseCard"
import courseSvc from "../services/Course"
import Loader from "../components/Loader"

const { coursesByField } = require("../data.json")

function Trainers() {
  const [courses, setCourses] = useState(coursesByField)
  useEffect(() => {
    ;(async () => {
      const courses = await courseSvc.fetchAllCourses().catch(err => {
        console.error(err)
        return []
      })

      setCourses(courses)
    })()
  }, [])

  const formInitialState = {
    name: "",
    phoneNumber: "",
    email: "",
    roles: [],
  }
  const [form, setForm] = useState(formInitialState)
  const [formErrors, setFormErrors] = useState({})
  const [formProcessing, setFormProcessing] = useState(false)

  const submitForm = () => {
    setFormProcessing(true)

    const errors = {}

    // Name
    if (!form.name) errors.name = "Please enter a name"

    // Email
    if (!form.email) errors.email = "Please enter an email"
    else if (
      !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        form.email
      )
    )
      errors.email = "Please enter a valid email"

    // Phone number
    if (!form.phoneNumber) errors.phoneNumber = "Please enter a phone number"
    else if (
      !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
        form.phoneNumber
      )
    )
      errors.phoneNumber = "Please enter a valid phone number"

    const newForm = { ...form }
    if (!form.roles.length) form.roles = "None of the above"

    if (Object.keys(errors).length === 0) {
      makePostRequest(`${backendHost}/send-email`, newForm)
        .then(({ data }) => {
          if (data.success) {
            alert("Successfully submitted!")
            setForm(formInitialState)
          } else alert("An error occured")
          setFormProcessing(false)
        })
        .catch(err => {
          console.error(err)
          alert("An error occured")
          setFormProcessing(false)
        })
    } else setFormErrors(errors)
  }

  const MultiSelectButton = props => {
    const { label, deselectAll } = props
    return (
      <button
        className={
          (!deselectAll && form.roles.includes(label)) ||
          (deselectAll && !form.roles.length)
            ? "w-full  mr-2 h-16 rounded-2xl border-4 shadow-md border-red-400 text-red-500"
            : "w-full  mr-2 h-16 rounded-2xl border shadow-md border-gray-200 blue-dark2"
        }
        style={{ outline: "none" }}
        onClick={e => {
          e.preventDefault()
          const newForm = { ...form }

          if (deselectAll) newForm.roles = []
          else {
            let index = -1
            const exists = newForm.roles.find((e, idx) => {
              if (e === label) {
                index = idx
                return true
              }
            })
            if (exists) newForm.roles.splice(index, 1)
            else newForm.roles.push(label)
          }

          setForm(newForm)
        }}
      >
        {label}
      </button>
    )
  }

  return (
    <Layout title="Skills trainer">
      <div className="relative">
        <img
          src={pageTopBg}
          className="absolute"
          style={{ zIndex: "-1" }}
          alt=""
        />
        <div className="content mx-auto">
          <div className="pb-20 pt-56 px-10 lg:px-0">
            <div className="text-6xl max-w-max mx-auto font-semibold line-heading relative text-center px-10 lg:px-0">
              <p className="text-4xl font-semibold orange-light1">
                Dear Trainers & Assessors
              </p>
              <img src={Circle} className="absolute -top-40 -left-32" />
              <p className="blue-dark2 text-4xl leading-relaxed md:text-5xl font-bold md:font-extrabold mt-14">
                Welecome To World's First Comprehensive{" "}
                <br className="md:block hidden" />
                Digital Trainer & Assessor Support Eco-System
              </p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row sm:items-center mx-auto">
            <div className="flex flex-col sm:flex-row xl:flex-col">
              <div className=" flex flex-col lg:flex-row today-box border-blue items-center lg:items-start ">
                <img src={one} />
                <p className="ml-4 font-semibold blue-dark2">
                  Join Skills Trainer & Assessor Community for Free
                </p>
              </div>
              <div className="flex flex-col sm:flex-row ">
                <div className="today-box border-blue">
                  <div className="flex flex-col lg:flex-row items-center lg:items-start ">
                    <img src={two} />
                    <p className="ml-8 font-semibold blue-dark2">
                      Get Government Approved & industry validated
                    </p>
                  </div>
                  <div className="flex items-start mt-10 px-8 items-start ">
                    <div className="h-3 mt-2 w-3  bg-blue-800 rounded-full">
                      &nbsp;&nbsp;
                    </div>
                    <p className="ml-14 mt-2 blue-dark2 font-semibold">
                      Training of Trainer (TOT)
                    </p>
                  </div>
                  <div className="flex items-start mt-10 px-8 items-start ">
                    <div className="h-3 mt-2 w-3  bg-blue-800 rounded-full">
                      &nbsp;&nbsp;
                    </div>
                    <p className="ml-14 mt-2 blue-dark2 font-semibold items-start ">
                      Training of Assessor (TOA) Cerificate
                    </p>
                  </div>
                  <div className="flex items-start mt-10 px-8 items-start ">
                    <div className="h-3 w-3 mt-2 bg-blue-800 rounded-full">
                      &nbsp;&nbsp;
                    </div>
                    <p className="ml-14  mt-2 blue-dark2 font-semibold">
                      Training Of Master Trainer (TOMT)
                    </p>
                  </div>
                  <div className="flex items-start mt-10 px-8 items-start ">
                    <div className="h-3 w-3 mt-2 bg-blue-800 rounded-full">
                      &nbsp;&nbsp;
                    </div>
                    <p className="ml-14 mt-2 blue-dark2 font-semibold">
                      Training Of Master Assessor (TOMA)
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-100 md:w-25">
                <div className="trainer-box blue-dark2 py-5">
                  <img src={teacherStudent} />
                  <p className="pt-6">No. of Training Sessions Facilitated</p>
                  <p className="text-3xl font-semibold my-8 orange-light1">
                    20,000+
                  </p>
                </div>
              </div>

              <div className="w-100 md:w-25">
                <div className="trainer-box blue-dark2 py-5">
                  <img src={pcclock} />
                  <p className="pt-6">Trainees Trained & Assesed</p>
                  <p className="text-3xl font-semibold my-8 orange-light1">
                    12,00,000+
                  </p>
                </div>
              </div>

              <div className="w-100 md:w-25">
                <div className="trainer-box blue-dark2 py-5">
                  <img src={money} />
                  <p className="pt-6">
                    Consulting Fees Generated for our Trainers & Assessors
                  </p>
                  <p className="text-3xl font-semibold my-8 orange-light1">
                    ₹ 100+Million
                  </p>
                </div>
              </div>
            </div>
            <div className="container flex gap-4 my-32">
              <div className="w-full md:w-2/5">
                <div className="text-4xl leading-relaxed md:text-5xl font-bold md:font-extrabold orange-light1">
                  Our Certification & Work Partners
                </div>
              </div>
              <div className="w-full md:w-3/5">
                <div className="flex overflow-x-auto gap-5">
                  <img src={partner11} className="p-2" />
                  <img src={partner12} className="p-2" />
                  <img src={partner5} className="p-2" />
                  <img src={partner9} className="p-2" />
                  <img src={partner13} className="p-2" />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-10 xl:flex-row sm:items-centre mx-auto">
              <div>
                <div className="masonry before:box-inherit after:box-inherit">
                  <div className="break-inside">
                    <div className=" flex flex-col lg:flex-row secondary-card border-blue items-center lg:items-start ">
                      <div
                        className="self-start text-3xl rounded-full text-white py-4 px-7 bg-origin-content"
                        style={{ backgroundColor: "#274553 " }}
                      >
                        1
                      </div>
                      <p className="ml-4 blue-dark2">
                        <span className="orange-light1">
                          Join Skills Trainer
                        </span>{" "}
                        & Assessor Community for{" "}
                        <span className="orange-light1">Free</span>
                      </p>
                    </div>
                  </div>
                  <div className="break-inside my-6">
                    <div className="secondary-card border-blue">
                      <div className="flex flex-col lg:flex-row items-center lg:items-start ">
                        <div
                          className="self-start text-3xl rounded-full text-white py-4 px-6 bg-origin-content"
                          style={{ backgroundColor: "#274553 " }}
                        >
                          2
                        </div>
                        <p className="ml-8 blue-dark2">
                          Get{" "}
                          <span className="orange-light1">
                            Government Approved & Industry Validated
                            Certifications
                          </span>{" "}
                          across multiple sectors for :
                        </p>
                      </div>
                      <div className="px-8 mt-8">
                        <ul className="list-disc blue-dark2 list-outside">
                          <li className="mb-3">Training of Trainer (TOT)</li>
                          <li className="mb-3">
                            Training of Assessor (TOA) Cerificate
                          </li>
                          <li className="mb-3">
                            Training Of Master Trainer (TOMT)
                          </li>
                          <li className="mb-3">
                            Training Of Master Assessor (TOMA)
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="break-inside my-6">
                    <div
                      className="flex flex-col lg:flex-row secondary-card border-blue items-start p-8"
                      style={{ height: "100%" }}
                    >
                      <div
                        className="self-start text-3xl rounded-full text-white py-4 px-6 bg-origin-content"
                        style={{ backgroundColor: "#274553 " }}
                      >
                        3
                      </div>

                      <p className=" ml-8 blue-dark2 leading-10 ">
                        <span className="orange-light1">
                          Enhance your Professional Training & Assessment Skills
                        </span>{" "}
                        for Digital Training & Classroom Formats
                      </p>
                    </div>
                  </div>
                  <div className="break-inside my-6">
                    <div
                      className="flex secondary-card border-blue items-top "
                      style={{ height: "100%" }}
                    >
                      <div
                        className="self-start text-3xl rounded-full text-white py-4 px-6 bg-origin-content"
                        style={{ backgroundColor: "#274553 " }}
                      >
                        4
                      </div>

                      <p className=" ml-8 blue-dark2 leading-10 ">
                        Get connected to thousands of{" "}
                        <span className="orange-light1">
                          Training & Assessment Projects
                        </span>{" "}
                        in India and Other Countries
                      </p>
                    </div>
                  </div>
                  <div className="break-inside my-6">
                    <div
                      className="flex secondary-card border-blue items-top "
                      style={{ height: "100%" }}
                    >
                      <div
                        className="self-start text-3xl rounded-full text-white py-4 px-6 bg-origin-content"
                        style={{ backgroundColor: "#274553 " }}
                      >
                        5
                      </div>
                      <p className=" ml-8 blue-dark2 leading-10 ">
                        <span className="orange-light1">
                          Enhance Your Income
                        </span>{" "}
                        by implementing projects through SkillsTrainer Training
                        Ecosystem which includes Sector Skill Councils,
                        Corporate, Training Companies, NGOs etc
                      </p>
                    </div>
                  </div>
                  <div className="break-inside my-6">
                    <div
                      className="flex secondary-card border-blue items-top "
                      style={{ height: "100%" }}
                    >
                      <div
                        className="self-start text-3xl rounded-full text-white py-4 px-6 bg-origin-content"
                        style={{ backgroundColor: "#274553 " }}
                      >
                        6
                      </div>
                      <p className=" ml-8 blue-dark2 leading-10 ">
                        Set up your own{" "}
                        <span className="orange-light1">
                          SkillsTrainer Digital Learning Academy
                        </span>
                        , one of world’s fastest growing and profitable
                        businesses
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="cover-image primary-card">
                  <img src={Offer1} className="p-4" />
                  <p className="blue-dark2 p-4 font-semibold">
                    K.J.SPannu, Jalandher, Punjab, India
                  </p>
                  <p className="blue-dark2 p-4 leading-10">
                    In July 2020, I got certified as a Master Trainer for
                    Platform Skills and multiple Domain Skills including
                    Management, Retail, Automotive and Prevention of Sexual
                    Harassment (POSH). Since then I have conducted over 200
                    Training Sessions with SkillsTrainer, Retail Sector Skill
                    Council, Automobile Sector Skill Council, Management Sector
                    Skill Council, BOSCH and multiple other clients.
                  </p>
                </div>
              </div>
            </div>

            {!courses && (
              <div className="w-full flex justify-center p-4">
                <Loader style={{ width: "130px" }} />
              </div>
            )}
            {courses && courses.length === 0 && <h2>No Courses available</h2>}
            {courses && courses.length > 0 && (
              <div className="my-32 grid gap-5 grid-cols-1 md:grid-cols-3 items-center justify-center flex-wrap">
                {courses.slice(1, 7).map(obj => (
                  <CourseCard data={obj} key={obj.courseId} />
                ))}
              </div>
            )}
            <div className="max-w-max mx-auto mt-14 mb-28">
              <Link to={"/courses"}>
                <button className="button btn-blue">View all</button>
              </Link>
            </div>

            <div className="bg-blue-grad">
              {/* <img src={Dot} className="relative top-25 left-100" /> */}
              <p className="p-4 text-center blue-dark2 text-5xl font-semibold pt-24">
                Training & Assessment Projects implemented
              </p>
              <p className="p-4 text-center blue-dark2 text-5xl font-semibold">
                Available Through Skillstrainer
              </p>
            </div>
            <div className="flex flex-col xl:flex-row items-center justify-evenly">
              <div className="flex flex-col ">
                <p className="orange-light1 text-6xl font-semibold ">
                  This Could
                  <br />
                  be you!
                </p>
                <p className="blue-dark2 text-2xl mt-12">
                  Register Now and become part of <br /> SkillsTrainer Trainer &
                  Assessor <br /> Community and transform your Carrer
                </p>
                <div>
                  <button className="button btn-red mt-10">
                    <a href={webappHost} target="_blank">
                      Register Now
                    </a>
                  </button>
                </div>
                <div className="primary-card">
                  <p>
                    Swar - Spoken English
                    <br />
                    Program - Teaching English in 3 months and 36 hours!
                  </p>
                  <p>
                    No of Trainees to be trained:
                    <br />
                    <span className="orange-light1 text-3xl font-semibold">
                      10 lakh+
                    </span>
                  </p>
                  <p className="font-semibold blue-dark2">Project Partners:</p>
                  <div className="flex flex-row justify-between">
                    <img src={newlogo} />
                  </div>
                </div>
                <div className="primary-card">
                  <p className="font-semibold blue-dark2">
                    Optical fibre specializing Programme
                  </p>
                  <p>
                    No of Trainees to be trained:
                    <br />
                    <span className="orange-light1 text-3xl font-semibold">
                      100,000+
                    </span>
                  </p>
                  <p className="font-semibold blue-dark2">Project Partners:</p>
                  <div className="flex flex-row justify-between">
                    <img src={partner9} />
                    <img src={newlogo} style={{ width: "50%" }} />
                  </div>
                </div>
                <div className="primary-card">
                  <p>Training of Trainer & Assessoor for Automobile Sector</p>
                  <p>
                    No of Trainees to be trained:
                    <br />
                    <span className="orange-light1 text-3xl font-semibold">
                      200,000+
                    </span>
                  </p>
                  <p className=" font-semibold blue-dark2">Project Partners:</p>
                  <div className="flex flex-row justify-between">
                    <img src={partner13} />
                    <img src={newlogo} style={{ width: "50%" }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="max-w-max mx-auto mt-28 mb-28">
              {/* <Link to={}> */}
              <button className="button btn-blue">See more</button>
              {/* </Link> */}
            </div>

            <div className="bg-blue-grad flex flex-col ">
              <div className="flex flex-col">
                <p className="p-4  blue-dark2 text-5xl font-semibold pt-24">
                  Training & Assessment Projects implemented
                </p>
                <p className="p-4  blue-dark2 text-5xl font-semibold">
                  Available Through Skillstrainer
                </p>
              </div>
              <div className="flex flex-col xl:flex-row items-center justify-evenly p-6">
                <div className="flex flex-col w-1/3">
                  <p className="orange-light1 text-6xl font-semibold ">
                    This Could
                    <br />
                    be you!
                  </p>
                  <p className="blue-dark2 text-2xl mt-12">
                    Register Now and become part of <br /> SkillsTrainer Trainer
                    & Assessor <br /> Community and transform your Carrer
                  </p>
                  <div>
                    <button className="button btn-red mt-10">
                      <a
                        href="https://lms.skillstrainer.in/moodle/login/signup.php"
                        target="_blank"
                      >
                        Register Now
                      </a>
                    </button>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row items-center w-2/3">
                  <div
                    className="primary-card story-cards-trainer m-5"
                    style={{ top: "10rem" }}
                  >
                    <img src={story1} className="w-full" />
                    <p className="text mt-3">
                      {" "}
                      K.J.SPannu, Jalandher, Punjab, India
                    </p>
                    <p className="text mt-3">
                      In July 2020, I got certified as a Master Trainer for
                      Platform Skills and multiple Domain Skills including
                      Management, Retail, Automotive and Preventation of Sexual
                      Harassment (Posh). Since then I hope conducted over 200
                      Training Sessions with Skills Trainer.
                    </p>
                  </div>
                  <div className="primary-card story-cards-trainer m-5">
                    <img src={story1} className="w-full" />
                    <p className="text mt-3">
                      K.J.SPannu, Jalandher, Punjab, India
                    </p>
                    <p className="text mt-3">
                      In July 2020, I got certified as a Master Trainer for
                      Platform Skills and multiple Domain Skills including
                      Management, Retail, Automotive and Preventation of Sexual
                      Harassment (Posh). Since then I hope conducted over 200
                      Training Sessions with Skills Trainer.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-blue-grad mt-56">
              <p className="p-4 text-center blue-dark2 text-5xl font-semibold pt-24">
                Register YourSelf To Join The Skillstrainer
              </p>
              <p className="p-4 text-center blue-dark2 text-5xl font-semibold pt-8">
                Trainer And Assessor Community
              </p>
              <div className="flex flex-col lg:flex-row items-center  mt-14 justify-evenly">
                <img
                  src={trainer2}
                  className="lg:w-1/2 w-96 rounded-3xl border-8 border-white"
                />

                <div
                  className="primary-card mt-16 lg:mt-0"
                  style={{ position: "relative", top: "6rem" }}
                >
                  <p>I am currently a</p>
                  <div className="flex flex-row mt-4 items-center ">
                    <MultiSelectButton label="Trainer" />
                    <MultiSelectButton label="Master trainer" />
                  </div>
                  <div className="flex flex-row mt-4 items-center ">
                    <MultiSelectButton label="Assessor" />
                    <MultiSelectButton label="Master assessor" />
                  </div>
                  <div className="flex flex-row mt-4 items-center ">
                    <MultiSelectButton label="None of the above" deselectAll />
                  </div>
                  {formErrors.roles}
                  <label for="Full Name" className="mt-4">
                    Full Name:
                  </label>
                  <input
                    type="text"
                    placeholder="Your First name"
                    className="primary-input"
                    onChange={wireEventValue(name =>
                      setForm({ ...form, name })
                    )}
                  />
                  <span className="error">{formErrors.name}</span>
                  <div className="flex flex-row mt-4 justify-between">
                    <div className="flex flex-col mt-4 mr-2">
                      <label for="Phone no">Phone No.</label>
                      <input
                        type="text"
                        placeholder="Your phone no."
                        className="primary-input"
                        onChange={wireEventValue(phoneNumber =>
                          setForm({ ...form, phoneNumber })
                        )}
                      />
                      <span className="error">{formErrors.phoneNumber}</span>
                    </div>
                    <div className="flex flex-col mt-4 ml-2">
                      <label for="Email">Email</label>
                      <input
                        type="text"
                        placeholder="Your Email Id"
                        className="primary-input"
                        onChange={wireEventValue(email =>
                          setForm({ ...form, email })
                        )}
                      />
                      <span className="error">{formErrors.email}</span>
                    </div>
                  </div>
                  <button
                    className="button-f btn-red mt-12 mx-auto w-full"
                    onClick={submitForm}
                    disabled={formProcessing}
                  >
                    Register Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Trainers
